import { defineComponent } from 'vue';
import QrcodeVue from 'qrcode.vue';
export default defineComponent({
    name: 'QrBox',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    components: {
        QrcodeVue,
    },
});
