import request from '@/core/utils/request';
/**
 * 查看授权列表
 * @param pageNumber
 * @param pageSize
 */
const getAuthorizationList = async (pageNumber, pageSize, cancelToken) => {
    const res = await request({
        url: '/airdrop/approve-list',
        method: 'get',
        params: {
            pageNumber,
            pageSize,
        },
        cancelToken,
    });
    if (res.data.error) {
        throw new Error(res.data.desc);
    }
    return {
        record: res.data.result.record,
        count: res.data.result.count,
    };
};
export default getAuthorizationList;
