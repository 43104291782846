import { defineComponent, ref, toRefs } from 'vue';
import useLock from '@/utils/useLock';
export default defineComponent({
    name: 'Dialog',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        target: {
            type: String,
            required: false,
            default: 'body',
        },
    },
    emits: ['close', 'update:modelValue'],
    setup(props, { emit }) {
        const maskRef = ref(null);
        const { modelValue } = toRefs(props);
        useLock(maskRef, modelValue);
        const close = () => {
            emit('update:modelValue', false);
            emit('close');
        };
        return {
            maskRef,
            close,
        };
    },
});
