import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Toast',
    props: {
        type: {
            type: String,
            required: false,
            default: 'success',
        },
        message: {
            type: String,
            required: true,
        },
    },
});
