import request from '@/core/utils/request';
import StorageServices from '../StorageServices';
const verifyApprove = async (chainType, tokenHash) => {
    const userAddress = StorageServices.getUserAdderss();
    const { data } = await request({
        url: '/airdrop/is-approve-for-all',
        method: 'get',
        params: {
            chainType,
            tokenHash,
            address: userAddress,
        },
    });
    return data;
};
export default verifyApprove;
