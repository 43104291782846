import request from '@/core/utils/request';
export const getLoginQr = async () => {
    const res = await request({
        method: 'get',
        url: '/user/qr-code/login',
    });
    const { result } = res.data;
    return {
        id: result.id,
        qr: JSON.stringify(result.qrCode),
    };
};
export const getLoginResult = async (id, cancelToken) => {
    const res = await request({
        method: 'get',
        url: `/user/qr-code/result/${id}`,
        cancelToken,
    });
    const { result } = res.data;
    if (result.state === 0) {
        throw new Error('unexpected:auth failed'); // 验证用户签名失败
    }
    return {
        state: result.state,
        ontId: result.ontId,
        auth: res.headers.authorization,
        address: result.address,
        chains: result.chainType,
    };
};
// remove test
export const testLogin = async () => {
    const res = await request({
        url: '/user/login',
    });
    const { result } = res.data;
    return {
        state: result.state,
        ontId: result.ontId,
        auth: res.headers.authorization,
    };
};
