import request from '@/core/utils/request';
/**
 * 项目方删除空投活动
 * @params taskId 空投任务id
 */
export const deleteTask = (taskId) => request({
    url: '/airdrop',
    method: 'delete',
    data: {
        id: taskId,
    },
});
/**
 * 项目方下架空投
 * @params taskId 空投任务id
 */
export const cancelTask = (taskId) => request({
    url: '/airdrop/cancel',
    method: 'put',
    data: {
        id: taskId,
    },
});
/**
 * 项目方发布(开启)空投活动
 * @params taskId 空投任务id
 */
export const releaseTask = (taskId) => request({
    url: '/airdrop/release',
    method: 'put',
    data: {
        id: taskId,
    },
});
