import request from '@/core/utils/request';
/**
 * 用户提交申领空投
 * @param taskForm
 * @param cancelToken
 */
const submitUserApplyTask = async (taskForm, cancelToken) => request({
    url: '/airdrop/involve',
    method: 'post',
    data: taskForm,
    cancelToken,
});
export default submitUserApplyTask;
