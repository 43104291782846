import request from '@/core/utils/request';
/**
 * 获取用户参与的空投活动列表(分页)
 */
export const getInvolvedTaskList = async (pageNumber, pageSize, cancelToken) => {
    const { data } = await request({
        url: '/airdrop/involved',
        method: 'get',
        params: {
            pageNumber,
            pageSize,
        },
        cancelToken,
    });
    return data.result;
};
/**
 * 获取用户参与的指定任务详情
 * @param taskId
 */
export const getInvolvedTask = async (taskId) => {
    const { data } = await request({
        url: `/airdrop/involved/detail/${taskId}`,
        method: 'get',
    });
    return data.result;
};
