// import LandingPage from '@/views/LandingPage/index.vue';
import { createRouter, createWebHistory } from 'vue-router';
import emitter from '@/utils/emitter';
import StorageServices from '../core/Services/StorageServices';
const routes = [
    {
        path: '/',
        // name: 'LandingPage',
        // component: LandingPage,
        redirect: '/inc',
    },
    // 用户
    // {
    //   path: '/user',
    //   name: 'User',
    //   component: () => import(/* webpackChunkName: "User" */ '@/views/User/index.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'UserHome',
    //       component: () => import(/* webpackChunkName: "UserHome" */ '@/views/User/UserHome/index.vue'),
    //     },
    //     {
    //       path: 'detail/:id',
    //       name: 'TaskDetail',
    //       component: () => import(/* webpackChunkName: "TaskDetail" */ '@/views/User/TaskDetail/index.vue'),
    //     },
    //   ],
    // },
    // 项目方
    {
        path: '/inc',
        name: 'Inc',
        component: () => import(/* webpackChunkName: "Inc" */ '@/views/Inc/index.vue'),
        children: [
            {
                path: '',
                name: 'IncHome',
                component: () => import(/* webpackChunkName: "IncHome" */ '@/views/Inc/IncHome.vue'),
            },
            // 批量空投代币
            {
                path: 'drop',
                name: 'Drop',
                component: () => import(/* webpackChunkName: "Drop" */ '@/views/Inc/Drop/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'SetDrop',
                        component: () => import(/* webpackChunkName: "SetDrop" */ '@/views/Inc/Drop/SetDrop/index.vue'),
                    },
                    {
                        path: 'gas',
                        name: 'SetGas',
                        component: () => import(/* webpackChunkName: "SetGas" */ '@/views/Inc/Drop/SetGas.vue'),
                    },
                ],
            },
            // 空投NFT
            {
                path: 'nft',
                name: 'NFT',
                component: () => import('@/views/Inc/NFT/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'SetDrop_NFT',
                        component: () => import('@/views/Inc/NFT/SetDrop/index.vue'),
                    },
                    {
                        path: 'gas',
                        name: 'SetGas_NFT',
                        component: () => import('@/views/Inc/NFT/SetGas.vue'),
                    },
                ],
            },
            // 发布空投任务
            {
                path: 'batch',
                name: 'Batch',
                component: () => import(/* webpackChunkName: "Batch" */ '@/views/Inc/Batch/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'EditTask',
                        component: () => import(/* webpackChunkName: "EditTask" */ '@/views/Inc/Batch/EditTask.vue'),
                    },
                    {
                        path: 'preview',
                        name: 'PreviewTask',
                        component: () => import(/* webpackChunkName: "PreviewTask" */ '@/views/Inc/Batch/PreviewTask.vue'),
                    },
                ],
            },
            // 用户数据看板
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Inc/Dashboard/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'BoardHome',
                        component: () => import(/* webpackChunkName: "BoardHome" */ '@/views/Inc/Dashboard/BoardHome.vue'),
                    },
                    {
                        path: 'task',
                        name: 'DashboardTaskDetail',
                        component: () => import(/* webpackChunkName: "DashboardTaskDetail" */ '@/views/Inc/Dashboard/TaskDetail.vue'),
                    },
                ],
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    const whiteList = ['/inc'];
    if (whiteList.indexOf(to.path) > -1) {
        next();
        return;
    }
    const auth = StorageServices.getLoginAuth();
    if (!auth) {
        next('/inc');
        emitter.emit('connect');
        return;
    }
    next();
});
export default router;
