import request from '@/core/utils/request';
/**
 * @description:
 * @param {number} id
 * @param {string} sign
 * @param {string} taskId
 * @param {1} isPass 1-通过；2-未通过
 * @return {*}
 */
const approveUserTask = async (id, taskId, isPass) => {
    const { data } = await request({
        url: '/airdrop/user-info',
        method: 'post',
        data: {
            airdropId: id,
            userInfoId: taskId,
            verification: isPass,
        },
    });
    return data;
};
export default approveUserTask;
