/**
 * File转base64
 * @param file
 */
export const readFile = (file) => new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => {
        res(reader.result);
    };
    reader.onerror = (e) => {
        rej(e);
    };
    reader.readAsDataURL(file);
});
/**
 * 获取图片文件尺寸(宽高)
 * @param file
 * @returns
 */
export const getImageSize = (file) => new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        if (!reader.result || typeof reader.result !== 'string') {
            rej(new Error('read file failed'));
            return;
        }
        const image = new Image();
        image.onload = () => {
            res({
                width: image.width,
                height: image.height,
            });
        };
        image.onerror = (e) => {
            rej(e);
        };
        image.src = reader.result;
    };
    reader.onerror = (e) => {
        rej(e);
    };
    reader.readAsDataURL(file);
});
/**
 * 生成一个简单的uuid
 */
export const getUuid = () => `${Date.now()}${Math.random()}`.replace('.', '');
/**
 * 毫秒时间戳转换为秒
 * @des api全部使用秒,在Backend层过滤
 * @param timestamp
 */
export const toApiTime = (timestamp) => Math.floor(timestamp / 1000);
export const fromApiTime = (apiTime) => apiTime * 1000;
/**
 * 生成随机文件名，带后缀
 * @param sourceName
 */
export const getRandomFileName = (sourceName) => `${getUuid()}${sourceName}`;
/**
 * 格式化长字符串，前后默认保留4位，中间省略
 * @param val 要格式化的字符串
 * @param mid 省略号
 * @param start 开头保留的位数
 * @param end 结尾保留的位数
 * @returns 省略后的字符串
 */
export const formatLongStr = (val, start = 4, end = 4, mid = '...') => {
    if (val.length > start + end) {
        return `${val.substr(0, start)}${mid}${val.substr(val.length - end, end)}`;
    }
    return val;
};
/**
 * 获取表格行序号
 * @param page 当前页 从1开始
 * @param size 每页条数
 * @param index 行序号 从0开始
 */
export const getTableIndex = (page, size, index) => (page - 1) * size + index + 1;
