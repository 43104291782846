import request from '@/core/utils/request';
import StorageServices from '../StorageServices';
/**
 * @description: 获取批量空投nft的gaslimit
 * @param {string} chainType
 * @param {string} tokenHash
 * @param {string} standard
 * @param {string} toAddress
 * @param {string} value
 * @param {string} tokenIds
 * @param {*} flag
 * @return {*}
 */
const getNftGasLimit = async (chainType, tokenHash, standard, toAddress, value, tokenIds, flag = true) => {
    const userAddress = StorageServices.getUserAdderss();
    const { data } = await request({
        url: '/airdrop/batch-airdrop-gas-nft',
        method: 'post',
        data: {
            chainType,
            tokenHash,
            standard,
            toAddress,
            value,
            tokenIds,
            address: userAddress,
            flag,
        },
    });
    return data;
};
export default getNftGasLimit;
