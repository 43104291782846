import { defineComponent, watch } from 'vue';
import NavBar from '@/components/NavBar.vue';
import { useI18n } from 'vue-i18n';
import { Lang } from './core/types/enums';
export default defineComponent({
    components: { NavBar },
    setup() {
        const { locale } = useI18n();
        watch(() => locale.value, (val) => {
            if (val === Lang.zh) {
                document.body.classList.remove('g-font-en');
                document.body.classList.add('g-font-cn');
            }
            else {
                document.body.classList.remove('g-font-cn');
                document.body.classList.add('g-font-en');
            }
        }, {
            immediate: true,
        });
    },
});
