import BaseButton from '@/components/BaseButton.vue';
import ConnectApp from '@/components/Connect/ConnectApp.vue';
import Dialog from '@/components/Dialog.vue';
// import LangSwitcher from '@/components/LangSwitcher.vue';
import Connect from '@/core/Interactors/Connect';
import emitter from '@/utils/emitter';
import { computed, defineComponent, onBeforeMount, provide, ref, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useAutoHeader from '@/utils/useAutoHeader';
import Toast from '@/components/Toast';
import { useI18n } from 'vue-i18n';
import ConnectedId from './ConnectedId.vue';
export default defineComponent({
    name: 'NavBar',
    components: {
        ConnectApp,
        Dialog,
        // LangSwitcher,
        BaseButton,
        ConnectedId,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const isShowConnect = ref(false);
        const connectedOntId = ref('');
        provide('connectedOntId', connectedOntId);
        const { isHeaderVisible } = useAutoHeader();
        const connectHandler = ({ ontId, auth, address, chains, }) => {
            connectedOntId.value = ontId;
            isShowConnect.value = false;
            Connect.login(auth, ontId, address, chains);
            Toast.success(t('login_success'));
        };
        const signOutHandler = () => {
            connectedOntId.value = '';
            Connect.signOut();
            router.push('/');
        };
        onBeforeMount(() => {
            const { auth, ontId, address, chains, } = Connect.getLoginStatus();
            if (auth && ontId) {
                connectedOntId.value = ontId;
                Connect.login(auth, ontId, address, chains);
            }
            emitter.on('connect', () => {
                isShowConnect.value = true;
            });
        });
        const toWhere = computed(() => {
            if (route.path.indexOf('inc') !== -1) {
                return '/inc';
            }
            if (route.path.indexOf('user') !== -1) {
                return '/user';
            }
            return '/';
        });
        return {
            toWhere,
            connectedOntId,
            isShowConnect,
            connectHandler,
            signOutHandler,
            isHeaderVisible,
        };
    },
});
