import { formatLongStr } from '@/core/utils';
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import copy from 'copy-to-clipboard';
import Dropdown from './Dropdown.vue';
import Toast from './Toast';
export default defineComponent({
    name: 'ConnectedId',
    props: {
        ontId: {
            type: String,
            required: true,
        },
    },
    components: {
        Dropdown,
    },
    emits: ['signOut'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const route = useRoute();
        const dropDownRef = ref(null);
        const currentPath = computed(() => route.matched[0].path);
        const closeDropdown = () => {
            if (!dropDownRef.value) {
                return;
            }
            dropDownRef.value.hide();
        };
        const emitSignOut = () => {
            emit('signOut');
        };
        const copyOntId = () => {
            copy(props.ontId);
            Toast.success(t('copy_success'));
        };
        return {
            t,
            closeDropdown,
            emitSignOut,
            currentPath,
            dropDownRef,
            formatLongStr,
            copyOntId,
        };
    },
});
