import QrView from '@/components/QrView.vue';
import Backend from '@/core/Services/Backend';
import { defineComponent, reactive, toRefs, onMounted, } from 'vue';
import useFetchResult from './useFetchResult';
export default defineComponent({
    name: 'ConnectApp',
    components: { QrView },
    emits: ['close', 'connected'],
    setup(props, { emit }) {
        const state = reactive({
            qrUrl: '',
            qrId: '',
        });
        const { startFetchResult } = useFetchResult((ontId, auth, address, chains) => {
            emit('connected', {
                ontId,
                auth,
                address,
                chains,
            });
        });
        const closeHandler = () => {
            emit('close');
        };
        onMounted(async () => {
            const { id, qr } = await Backend.getLoginQr();
            state.qrUrl = qr;
            state.qrId = id;
            startFetchResult(id);
        });
        // const testLogin = async () => {
        //   const { ontId, auth } = await Backend.testLogin();
        //   emit('connected', {
        //     ontId,
        //     auth,
        //   });
        // };
        return {
            ...toRefs(state),
            closeHandler,
        };
    },
});
