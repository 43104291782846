import StorageServices from '../Services/StorageServices';
class Connect {
    /**
     * 获取登录二维码
     * @desc auth header由axios拦截器设置
     */
    static login(auth, ontId, address, chains) {
        StorageServices.setLoginAuth(auth);
        StorageServices.setLoginOntId(ontId);
        StorageServices.setUserAdderss(address);
        StorageServices.setUserChains(chains);
    }
    /**
     * 获取本地登录状态
     */
    static getLoginStatus() {
        return {
            auth: StorageServices.getLoginAuth(),
            ontId: StorageServices.getLoginOntId(),
            address: StorageServices.getUserAdderss(),
            chains: StorageServices.getUserChains(),
        };
    }
    static signOut() {
        StorageServices.setLoginAuth('');
        StorageServices.setLoginOntId('');
        StorageServices.setUserAdderss('');
        StorageServices.setUserChains([]);
    }
}
export default Connect;
