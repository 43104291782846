import { getShift, getTimes } from '@/core/utils/number';
/**
 * 获取手续费数量（不带精度）
 * @desc 当前手续费精度都是18
 * @param gwei
 * @param gasLimit
 */
// gwei * gaslimit /（精度(原生币)/（10的9次方））
// 原生币精度固定为10**18，所以简化为 (gwei/10**9) * gaslimit
export const getGasAmount = (gwei, gasLimit) => getShift(getTimes(gwei, gasLimit), -9);
/**
 * 获取带精度的数量
 * @param amount
 * @param decimal
 */
export const getAmountWithDecimal = (amount, decimal) => getShift(amount, decimal);
/**
 * 获取gasPrice wei
 * @param gwei
 */
export const getWeiFromGwei = (gwei) => getShift(gwei, 9);
