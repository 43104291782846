import { createApp } from 'vue';
import ToastView from './Toast.vue';
const Toast = (options) => {
    const props = typeof options === 'string'
        ? {
            message: options,
        }
        : options;
    const toastIns = createApp(ToastView, {
        message: props.message,
        duration: props.duration,
        type: props.type,
    });
    const parent = document.getElementById('toast-list');
    if (!parent) {
        const newParent = document.createElement('div');
        newParent.setAttribute('id', 'toast-list');
        document.body.appendChild(newParent);
    }
    const container = document.createElement('div');
    toastIns.mount(container);
    window.setTimeout(() => {
        toastIns.unmount();
    }, props.duration || 2000);
};
Toast.success = (message) => {
    Toast(message);
};
Toast.error = (message) => {
    Toast({
        message,
        type: 'error',
    });
};
export default Toast;
