import { apiTask2LocalTask } from '@/core/Services/Backend/taskFormat';
import request from '@/core/utils/request';
/**
 * 查询空投任务详情
 * @param taskId 空投任务id
 */
const getTaskDetail = async (taskId) => {
    const { data } = await request({
        url: `/airdrop/detail/${taskId}`,
        method: 'get',
    });
    if (data.error) {
        throw new Error(data.desc);
    }
    return apiTask2LocalTask(data.result);
};
export default getTaskDetail;
