import request from '@/core/utils/request';
/**
 * 导入批量空投文件
 * @param file
 * @returns
 */
export const importAirdrop = async (file, cancelToken, standard) => {
    const formData = new FormData();
    formData.append('file', file);
    if (standard) {
        formData.append('standard', standard);
    }
    const { data } = await request({
        url: '/common/upload/airdrop-list',
        method: 'post',
        data: formData,
        cancelToken,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const list = data.result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return list.map((item) => ({
        address: item.address,
        count: item.amount && item.amount,
        tokenId: item.tokenId && item.tokenId,
    }));
};
/**
 * todo 导出批量空投excel文件
 * @param airdropId
 */
export const exportAirdrop = async (airdropId) => {
    request({
        url: '/common/export/airdrop-list',
        params: {
            airdropId,
        },
        responseType: 'blob',
    }).then((res) => {
        const blob = res.data;
        const link = document.createElement('a');
        const body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = decodeURI('Submitted-list');
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    });
};
