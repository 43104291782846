import { ref, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
const WHITE_LIST = ['/', '/inc'];
const useAutoHeader = () => {
    const isHeaderVisible = ref(true);
    const oldTop = ref(0);
    const route = useRoute();
    const scrollHandler = () => {
        const top = document.documentElement.scrollTop;
        isHeaderVisible.value = top < oldTop.value || top < 100;
        oldTop.value = top;
    };
    watch(() => route.path, (val) => {
        if (WHITE_LIST.indexOf(val) > -1) {
            isHeaderVisible.value = true;
            document.removeEventListener('scroll', scrollHandler);
        }
        else {
            document.addEventListener('scroll', scrollHandler);
        }
    });
    onBeforeUnmount(() => {
        document.removeEventListener('scroll', scrollHandler);
    });
    return {
        isHeaderVisible,
    };
};
export default useAutoHeader;
