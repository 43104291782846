import request from '@/core/utils/request';
/**
 * 获取扫码结果
 * @param id
 * @returns
 */
const getQrResult = async (id, cancelToken) => {
    const { data } = await request({
        url: `/common/qr-code/result/${id}`,
        method: 'get',
        cancelToken,
    });
    return data.result;
};
export default getQrResult;
