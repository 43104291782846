/**
 * 有关链类型/币种等
 */
import request from '@/core/utils/request';
/**
 * 获取当前支持的链类型
 */
export const getChains = async () => {
    const { data } = await request({
        url: '/airdrop/support-chain',
        method: 'get',
    });
    return data.result;
};
/**
 * 查询精度
 * @param chain
 * @param symbolOrHash
 * @returns
 */
export const getDecimal = async (chain, symbolOrHash) => {
    const { data } = await request({
        url: '/airdrop/token-decimal',
        method: 'get',
        params: {
            chainType: chain,
            tokenHash: symbolOrHash,
        },
    });
    return data.result;
};
