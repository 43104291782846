import { getWeiFromGwei } from '@/core/utils/count';
import request from '@/core/utils/request';
/**
 * @description: 请求空投nft的二维码
 * @param {string} chainType
 * @param {string} hash
 * @param {NftAddressItem} addressList
 * @param {string} standard
 * @param {string} gasLimit
 * @param {string} gasPrice
 * @return {*}
 */
const getNftDropQr = async (chainType, hash, addressList, standard, gasLimit, gasPrice) => {
    const { data } = await request({
        url: '/airdrop/batch-airdrop-nft',
        method: 'post',
        data: {
            chainType,
            flag: true,
            tokenHash: hash,
            standard,
            toAddress: addressList.map((item) => item.address),
            tokenIds: addressList.map((item) => item.tokenId),
            value: addressList.map((item) => item.count),
            gasLimit,
            gasPrice: getWeiFromGwei(gasPrice),
        },
    });
    const { result } = data;
    return {
        id: result.id,
        qr: JSON.stringify(result.qrCode),
    };
};
export default getNftDropQr;
