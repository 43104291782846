import request from '@/core/utils/request';
import StorageServices from '../StorageServices';
/**
 * @description: 验证登录的账户是否有nft的所有权，也可以用于检查发送数量是否超过拥有数量
 * @param {string} chainType
 * @param {string} contract
 * @param {string} standard
 * @param {TokenItem} tokens
 * @return {*}
 */
const verifyOwnership = async (chainType, contract, standard, tokens) => {
    const userAddress = StorageServices.getUserAdderss();
    const { data } = await request({
        url: '/airdrop/verify-ownership',
        method: 'post',
        data: {
            chainType,
            address: userAddress,
            contract,
            standard,
            tokens: tokens.map((item, index) => ({
                tokenId: item.tokenId,
                value: item.value,
                id: index,
            })),
        },
    });
    return data.result;
};
export default verifyOwnership;
