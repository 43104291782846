{
  "error_61001": "参数错误",
  "error_61002": "数据未找到",
  "error_61003": "调用合约失败",
  "error_61004": "扫码校验失败",
  "error_61005": "二维码已过期",
  "error_61006": "二维码已扫描",
  "error_62001": "空投状态错误",
  "error_62002": "不在活动期间",
  "error_62003": "导出excel异常",
  "error_62004": "文件格式错误",
  "error_62005": "解析文件错误",
  "error_62006": "超出400行限制",
  "error_62007": "文件操作异常",
  "error_63001": "校验资产失败",
  "error_63002": "登录已过期",
  "error_63003": "资产类型错误",
  "error_64001": "调用第三方接口通信异常",
  "sun": "日",
  "mon": "一",
  "tue": "二",
  "wed": "三",
  "thu": "四",
  "fri": "五",
  "sat": "六",
  "january": "一月",
  "february": "二月",
  "march": "三月",
  "april": "四月",
  "may": "五月",
  "june": "六月",
  "july": "七月",
  "august": "八月",
  "september": "九月",
  "october": "十月",
  "november": "十一月",
  "december": "十二月",
  "cancel": "取消",
  "set_time": "设置时间",
  "login": "登录",
  "scan_login": "扫码登录",
  "open_onto_scan": "打开ONTO App扫码登录",
  "open_onto_scan_tip": "在「资产」页面顶部打开扫一扫，如扫码异常请下载或更新到最新版客户端",
  "download_onto_app": "下载ONTO App",
  "scan_download_onto": "扫码下载ONTO App",
  "close_qr": "关闭二维码",
  "supported_chains": "已支持公链",
  "eth_mainnet": "以太坊主网",
  "binance_smart_chain": "币安智能链",
  "Huobi_ECO_chain": "火币生态链",
  "okex_chain": "OKC链",
  "welcome_to_use": "欢迎使用",
  "assistant_to_drop": "空投助手",
  "inc_introduce": "通过ONTO Anydrop可批量空投资产，也可以定制空投任务，让用户在完成任务后领取奖励",
  "batch_airdrops_of_tokens": "批量空投资产",
  "batch_airdrops_sub": "对已有名单内地址进行批量空投",
  "release_the_airdrop_mission": "发布空投任务",
  "launch_small_airdrop_missions": "发布小型运营空投活动",
  "to_publish_task": "去发布任务",
  "data_board": "用户数据看板",
  "view_published_airdrop_data": "查看已发布的空投相关数据",
  "view_data_board": "查看数据看板",
  "pager_left": "第",
  "pager_right": "页",
  "click_upload_pic": "点击上传图片",
  "drag_here": "或将文件拖到这里",
  "re-upload": "重新上传图片",
  "onto_anydrop": "ONTO Anydrop",
  "airdrop_action": "空投活动",
  "my_joined": "我的活动",
  "airdrop_before_start": "未开始",
  "airdrop_active": "进行中",
  "airdrop_end": "已结束",
  "check_content": "查看内容",
  "remain_time": "剩余时间：{days}天{hours}小时{minutes}分钟",
  "task_name": "活动名称",
  "task_token": "空投资产",
  "task_token_count": "空投资产数",
  "status": "状态",
  "check": "查看",
  "acton_time": "活动时间",
  "acton_rule": "活动规则",
  "requirement": "申领条件",
  "go_do": "去完成",
  "apply_airdrop": "申领空投",
  "go_link": "前往链接",
  "confirm": "确定",
  "index": "序号",
  "address": "地址",
  "count": "数量",
  "add_line": "新增行",
  "problem": "问题",
  "invalid_address": "无效的钱包地址",
  "invalid_count": "无效的发放数量",
  "invalid_address_count": "无效的钱包地址和发送数量",
  "invalid_precision": "发送数量精度错误",
  "operate_step": "操作步骤",
  "task_step1": "1. 填写活动时间",
  "task_step1_content": "设置活动开始时间点和结束时间点，用户只有在活动期间才可参与活动。",
  "task_step2": "2. 选择空投地址类型",
  "task_step2_content": "设置空投地址类型后，用户在填写活动表单时只能填写该类型的钱包地址。",
  "task_step3": "3. 填写描述",
  "task_step3_content": "请分别填写对应语言的活动描述，包含活动标题、活动详情等，相关信息仅展示给选择该语言的用户。",
  "task_step4": "4. 添加申领条件",
  "task_step4_content": "“条件描述”：是展示给用户的申领条件介绍；“跳转链接”（可选）：给完成任务的用户提供快捷入口；“条件类型”：用户可在此提交信息，输入相关文案内容和上传相关图片。可点击下方查看样例进行参考，也可预览当前表单的展示情况。",
  "airdrop_address_type": "空投地址类型",
  "task_inc_name": "活动发起方",
  "enter_task_inc_name": "请输入发起方名称",
  "task_title": "活动标题",
  "enter_task_title": "请输入活动标题",
  "task_detail": "活动详情",
  "enter_task_detail": "请输入活动规则、发奖时间等相关介绍",
  "click_add_requirement": "点击添加申领条件",
  "des_and_picture": "描述和图片",
  "fit_lang": "（对应展示语言）",
  "enter_start_time": "请选择开始时间",
  "enter_end_time": "请选择结束时间",
  "next_step": "下一步",
  "optional": "（选填）",
  "avatar": "头像",
  "avatar_tip": "图片支持PNG或JPG格式，大小不超过1MB，宽高比须为1:1",
  "top_banner": "顶部页面",
  "top_banner_tip": "图片支持PNG或JPG格式，大小不超过3MB，宽高比须为2:1",
  "add_requirement": "添加条件",
  "requirement_des": "条件描述",
  "for_user": "以下内容将直接展示给用户",
  "enter_requirement_des": "例：BNB资产余额大于0.5",
  "jump_link": "跳转链接",
  "jump_link_tip": "选填，此链接为用户完成任务的快捷入口",
  "enter_jump_link": "请输入跳转链接",
  "requirement_type": "条件类型",
  "edit_text": "填写文本",
  "upload_picture": "上传图片",
  "text_title": "文本标题",
  "hour": "时",
  "minute": "分",
  "task_picture_tip": "图片支持PNG或JPG格式，大小不超过0.5MB，宽高比须为1:1，最多可上传3张图片",
  "slogan": "欢迎使用\nONTO Anydrop空投助手",
  "user": "用户",
  "user_intro": "您可以通过使用ONTO Anydrop参与和查看空投活动",
  "learn_more": "了解更多",
  "inc": "项目方",
  "inc_intro": "通过ONTO Anydrop可批量空投资产，也可以定制空投任务，让用户在完成任务后领取奖励",
  "task_preview": "活动页面预览",
  "web_end": "网页端",
  "mobile_end": "移动端",
  "submit_time": "提交时间",
  "airdrop_address": "空投地址",
  "upload_three": "图片支持PNG或JPG格式，大小不超过3MB，最多可上传3张图片",
  "please_enter_address": "请填写接收空投的钱包地址",
  "ensure_correct_address": "请确保填写正确的钱包地址",
  "submit_success": "提交成功",
  "no_task_tip": "当前还没有项目方发布空投活动",
  "no_join_tip": "当前没有参与空投",
  "apply_has_submitted": "已提交过申领信息",
  "done": "已完成",
  "language": "语言",
  "sign_out": "退出登录",
  "switch_to_user": "切换到用户",
  "switch_to_inc": "切换到项目方",
  "click_upload_file": "点击此处上传文件",
  "file_example": "文件示例",
  "select_or_input_hash": "请选择资产种类或输入合约地址",
  "scan_contract": "扫码确认交易",
  "over_size_limit": "文件大小超出限制",
  "over_rate_limit": "图片比例超出限制",
  "over_type_limit": "文件类型超出限制",
  "airdrop_address_count": "空投地址数",
  "no_drop_tip": "您还没有批量空投过资产，点击右下角按钮进行批量空投",
  "apply_count": "申领人数",
  "edit_content": "编辑内容",
  "check_data": "查看数据",
  "publish_task": "发布活动",
  "cancel_task": "下架活动",
  "no_publish_task_tip": "您还没有发布过空投任务，点击右下角按钮发布任务",
  "already_auth_token": "已授权资产",
  "cancel_auth": "取消授权",
  "back": "返回",
  "multi_drop_detail": "批量空投详情",
  "transfer_count": "转账次数",
  "airdrop_token_count": "空投资产总数",
  "fee": "手续费",
  "estimate_fee":"预估手续费",
  "airdrop_record": "空投记录",
  "enter_airdrop_address": "请输入空投地址",
  "select_token": "请选择资产类型",
  "time": "时间",
  "show_all": "显示全部",
  "failed": "失败",
  "apply_list": "申领列表",
  "airdrop_amount": "空投金额",
  "save": "保存",
  "all": "全部",
  "review_pending": "待审核",
  "passed":"已通过",
  "refused": "未通过",
  "not_drop": "未空投",
  "already_drop": "已空投",
  "chain_type": "公链类型",
  "decimal": "精度",
  "receive_address_list": "收款地址列表",
  "support_file_types": "支持文件类型：Excel、CSV或TXT",
  "drop_step1": "1. 选择空投资产所在的公链类型",
  "drop_step1_content": "支持以太坊、币安智能链、Polygon链和OKEx链。",
  "drop_step2": "2. 选择要空投的资产种类或输入资产合约地址",
  "drop_step2_content": "支持全部基于ERC-20、BEP-20、ERC-20（Polygon）和KIP-20协议的数字资产，输入资产合约地址将自动识别精度。",
  "drop_step3": "3. 上传空投名单文件",
  "drop_step3_content": "上传成功后系统会自动查重并识别异常数据，可在线对其进行编辑，清理完异常数据后点击下一步。",
  "confirm_balance_tip": "提示：请确保钱包的资产余额足够支付空投金额和交易手续费",
  "transfer_fee": "交易手续费",
  "please_enter": "请输入",
  "custom": "自定义",
  "auth_title": "授权",
  "check_demo": "查看样例",
  "preview_form": "预览表单",
  "unfilled": "未填写",
  "inc_name_unfilled": "项目方名称未填写",
  "time_unfilled": "活动时间未填写",
  "rule_unfilled": "1. 活动规则未添加",
  "requirement_unfilled": "申领条件未添加",
  "authorization_limit":"授权额度",
  "send":"发送",
  "please_upload_excel": "不支持的文件类型，请上传 Excel、CSV或TXT类型的文件",
  "please_upload_image": "不支持的文件类型，请上传PNG或JPG类型的文件",
  "copy_success": "已复制",
  "contract_not_found": "未找到该合约",
  "requirement_not_filled": "未提交申领条件",
  "submit_task_success": "保存空投任务成功",
  "enter_valid_address": "请输入正确的地址",
  "no_time": "请选择活动时间",
  "invalid_time": "开始时间不能晚于结束时间",
  "no_lang_form": "请填写活动描述并上传图片",
  "not_filled_lang_form": "有活动描述或图片必填项未填写",
  "not_matched_requirement": "多语言申领条件不匹配",
  "NotRelease": "未发布",
  "BeforeStart":"未开始",
  "Active":"进行中",
  "End":"已结束",
  "no_authorization":"暂无授权",
  "login_success": "登录成功",
  "drop_form_invalid": "批量空投表单填写不完整",
  "delete_invalid_address": "删除无效地址",
  "keep_duplicate_address": "保留重复项",
  "keep_first_one": "保留第一项",
  "address_duplicate": "以下钱包地址有重复，请检查",
  "address_or_amount_invalid": "以下地址或金额有误，无法发送",
  "start_time_before_now": "开始时间不能早于当前时间",
  "pass":"通过",
  "not_pass":"未通过",
  "review_success":"审核通过",
  "batch_drop":"批量空投",
  "aerial_mission":"空投任务",
  "authorization_management":"授权管理",
  "send_successfully":"发送成功",
  "low":"慢速",
  "average":"常速",
  "high":"快速",
  "number_of_tokens_sent":"代币发送数量",
  "transaction_closed":"交易已完成",
  "trading_hash":"交易哈希",
  "polygon_chain":"Polygon",
  "total_size": "共{total}条，每页显示{size}条",
  "the_authorization":"授权中",
  "please_wait":"交易即将完成，请勿关闭当前页面",
  "authorization_success":"授权成功",
  "authorization_fails":"授权失败！请重新授权",
  "transform":"转换",
  "transform_hint":"ex开头的地址须转换为0x开头",
  "drop_nft": "批量空投NFT",
  "drop_nft_sub":"对已有名单内的地址批量空投NFT",
  "nft_contract_address": "NFT合约地址",
  "enter_nft_contract_address": "请输入NFT合约地址",
  "contract_standard": "合约标准",
  "upload_form": "上传表格",
  "manual_input": "手动输入",
  "nft_step1": "1. 选择NFT所在的公链类型",
  "nft_step1_content": "支持以太坊、币安智能链、Polygon链和OKC链上的NFT空投。但需提前在ONTO上创建、导入或添加相应链的钱包，且保证钱包内有需空投的NFT和该链的手续费。",
  "nft_step2": "2. 输入NFT合约地址",
  "nft_step2_content": "支持全部ERC-721/1155、BEP-721/1155、ERC-721/1155 (Polygon) 和KIP-721/1155标准的NFT空投",
  "nft_step3": "3. 上传含空投名单的文件",
  "nft_step3_content": "上传成功后系统会自动查重并识别异常数据，可在线对其进行编辑和清理后，再点击下一步进入授权操作",
  "invalid_address_or_tokenid_or_quantity":"无效的钱包地址/ 无效的Token ID/ 无效的空投数量",
  "address_not_in_wallet":"以下Token不在当前钱包地址下",
  "no_ownership":"该Token不属于当前钱包",
  "nft_contract":"NFT合约",
  "number_of_nft_airdropped":"空投NFT总数",
  "tokenid_repetition":"以下Token ID有重复",
  "nft_excess":"发送的NFT数量超过地址内拥有的NFT数量"
}
