import { toApiTime } from '@/core/utils';
import request from '@/core/utils/request';
/**
 * 项目方修改空投任务
 * @param task
 * @param cancelToken
 */
const editTask = (task, cancelToken) => request({
    url: '/airdrop',
    method: 'put',
    data: {
        id: task.id,
        chainType: task.chain,
        endTime: toApiTime(task.endTime),
        startTime: toApiTime(task.startTime),
        airdropDetail: task.infos.map((info) => ({
            banner: info.banner,
            conditionStr: info.title,
            content: info.detail,
            language: info.lang.toUpperCase(),
            logo: info.incAvatar,
            sponsor: info.incName,
            title: info.title,
            condition: info.requirements.map((requirement) => ({
                id: requirement.id,
                link: requirement.link,
                name: requirement.des,
                detail: requirement.fields.map((field) => ({
                    description: field.title,
                    type: field.type,
                })),
            })),
        })),
    },
    cancelToken,
});
export default editTask;
