import { fromApiTime } from '@/core/utils';
/**
 * 接口的详情对象转换为本地Task对象
 * @param apiTask 接口的详情对象
 */
export const apiTask2LocalTask = (apiTask) => ({
    id: apiTask.id,
    chain: apiTask.chainType,
    chainLabel: apiTask.chain,
    startTime: fromApiTime(apiTask.startTime),
    endTime: fromApiTime(apiTask.endTime),
    status: apiTask.status,
    applicationCount: apiTask.applicationCount,
    infos: apiTask.airdropDetail.map((info) => {
        const airdropInfo = {
            lang: info.language.toLowerCase(),
            banner: info.banner,
            title: info.title,
            incAvatar: info.logo,
            incName: info.sponsor,
            detail: info.content,
            requirements: info.condition.map((condition) => {
                const requirement = {
                    id: condition.id,
                    des: condition.name,
                    link: condition.link,
                    fields: condition.detail.map((detail) => {
                        const field = {
                            id: `${Math.random()}}`,
                            title: detail.description,
                            type: detail.type,
                        };
                        return field;
                    }),
                };
                return requirement;
            }),
        };
        return airdropInfo;
    }),
});
