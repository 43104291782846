import { getRandomFileName } from '@/core/utils';
import request from '@/core/utils/request';
/**
 * 上传图片到s3
 * @param base64 图片base64
 * @param name 文件原始名称
 */
const uploadPicture = async (base64, name) => {
    const { data } = await request({
        url: '/common/s3/upload-file',
        method: 'post',
        data: {
            pictureBase64: base64,
            pictureName: getRandomFileName(name),
        },
    });
    if (data.error) {
        throw new Error(data.desc);
    }
    return data.result;
};
export default uploadPicture;
