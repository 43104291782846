import { Lang } from '@/core/types/enums';
const KEY = 'anydrop-lang';
class Language {
}
/**
 * 按照优先级加载当前语言
 * 1.本地缓存的语言
 * 2.浏览器的语言
 * 3.默认en
 */
Language.getCurrentLang = () => {
    const localLang = localStorage.getItem(KEY);
    if (localLang) {
        return localLang;
    }
    // const browserLanguages = navigator.languages;
    // if (browserLanguages[0] === 'zh-CN' || browserLanguages[0] === 'zh') {
    //   return Lang.zh;
    // }
    return Lang.en;
};
Language.saveLang = (lang) => {
    localStorage.setItem(KEY, lang);
};
Language.getLanguages = () => [
    {
        label: 'English',
        value: Lang.en,
    },
];
export default Language;
