import { getAmountWithDecimal, getWeiFromGwei } from '@/core/utils/count';
import request from '@/core/utils/request';
/**
 * @description: 请求发布批量空头二维码
 * @param {string} chainType
 * @param {string} hash
 * @param {AddressItem} addressList
 * @param {number} precision
 * @param {string} gasLimit
 * @param {string} gasPrice (单位是gwei)
 * @return {*}
 */
const getDropQr = async (chainType, hash, addressList, precision, gasLimit, gasPrice) => {
    const { data } = await request({
        url: '/airdrop/batch-airdrop',
        method: 'post',
        data: {
            chainType,
            flag: true,
            toAddress: addressList.map((item) => item.address),
            tokenHash: hash,
            value: addressList.map((item) => getAmountWithDecimal(item.count, precision)),
            gasLimit,
            gasPrice: getWeiFromGwei(gasPrice),
        },
    });
    const { result } = data;
    return {
        id: result.id,
        qr: JSON.stringify(result.qrCode),
    };
};
export default getDropQr;
