import { apiTask2LocalTask } from '@/core/Services/Backend/taskFormat';
import request from '@/core/utils/request';
/**
 * 获取空投活动列表
 * @param type 用户/项目方查询
 * @param page 从1开始
 * @param size 默认10
 */
const getTaskList = async (type, page, size = 10) => {
    const res = await request({
        url: type === 'user' ? '/airdrop' : '/airdrop/issuer-airdrop',
        params: {
            pageNumber: page,
            pageSize: size,
        },
    });
    const { result } = res.data;
    const apiList = result.record;
    const list = apiList.map((item) => apiTask2LocalTask(item));
    return {
        count: result.count,
        record: list,
    };
};
/**
 * 用户查询空投任务列表
 * @param page 从1开始
 * @param size 默认10
 */
export const getUserTaskList = (page, size = 10) => getTaskList('user', page, size);
/**
 * 项目方查询空投任务列表
 * @param page 从1开始
 * @param size 默认10
 */
export const getIncTaskList = (page, size = 10) => getTaskList('inc', page, size);
