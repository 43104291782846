import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseButton',
    props: {
        label: {
            type: String,
            required: false,
            default: '',
        },
        type: {
            type: String,
            required: false,
            default: 'normal',
            validator(val) {
                // dialog 用于弹窗的取消按钮
                return ['normal', 'primary', 'plain', 'dialog', 'label', 'mini'].includes(val);
            },
        },
        size: {
            type: String,
            required: false,
            default: 'big',
            validator(val) {
                return ['big', 'small', 'danger'].includes(val);
            },
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: String,
            required: false,
            default: 'auto',
        },
    },
    emits: ['click'],
    methods: {
        clickHandler(e) {
            if (this.disabled) {
                return;
            }
            this.$emit('click', e);
        },
    },
});
