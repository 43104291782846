/**
 * @name Backend
 * @author yuanzeyu
 * @date 2021/6/18
 * @desc
 */
import editTask from '@/core/Services/Backend/editTask';
import { getInvolvedTask, getInvolvedTaskList } from '@/core/Services/Backend/getInvolvedTask';
import getTaskDetail from '@/core/Services/Backend/getTaskDetail';
import { getIncTaskList, getUserTaskList } from '@/core/Services/Backend/getTaskList';
import { cancelTask, deleteTask, releaseTask } from '@/core/Services/Backend/manageTask';
import submitUserApplyTask from '@/core/Services/Backend/submitUserApplyTask';
import uploadPicture from '@/core/Services/Backend/uploadPicture';
import { getAuthQr } from '@/core/Services/Backend/authorize';
import { testLogin, getLoginResult, getLoginQr } from './connect';
import { getDecimal, getChains } from './chains';
import { importAirdrop, exportAirdrop } from './file';
import submitTask from './submitTask';
import getDropList from './getDropList';
import getAuthorizationList from './getAuthorizationList';
import getQrResult from './getQrResult';
import getDropQr from './dropQr';
import getTaskDetailList from './getTaskDetailList';
import getDropDetailList from './getDropDetailList';
import getGasOracle from './getGasOracle';
import getGasLimit from './getGasLimit';
import approveUserTask from './approveUserTask';
import getContractStandard from './getContractStandard';
import verifyOwnership from './verifyOwnership';
import getNftGasLimit from './getNftGasLimit';
import getNftDropQr from './nftDropQr';
import getNftDropList from './getNftDropList';
import verifyApprove from './verifyApprove';
class Backend {
}
Backend.getInvolvedTaskList = getInvolvedTaskList;
Backend.getInvolvedTask = getInvolvedTask;
Backend.submitUserApplyTask = submitUserApplyTask;
Backend.submitTask = submitTask;
Backend.editTask = editTask;
Backend.getLoginQr = getLoginQr;
Backend.getLoginResult = getLoginResult;
Backend.getUserTaskList = getUserTaskList;
Backend.getIncTaskList = getIncTaskList;
Backend.releaseTask = releaseTask;
Backend.deleteTask = deleteTask;
Backend.cancelTask = cancelTask;
Backend.getTaskDetail = getTaskDetail;
Backend.uploadPicture = uploadPicture;
Backend.getDropList = getDropList;
Backend.getAuthorizationList = getAuthorizationList;
Backend.getAuthQr = getAuthQr;
Backend.getTaskDetailList = getTaskDetailList;
Backend.getDropDetailList = getDropDetailList;
// 导入批量空投文件
Backend.importAirdrop = importAirdrop;
// 导出批量空投excel文件
Backend.exportAirdrop = exportAirdrop;
// 获取当前支持的链类型
Backend.getChains = getChains;
// 查询精度
Backend.getDecimal = getDecimal;
// 获取扫码结果
Backend.getQrResult = getQrResult;
// 获取批量空投二维码
Backend.getDropQr = getDropQr;
Backend.testLogin = testLogin;
Backend.getGasOracle = getGasOracle;
Backend.getGasLimit = getGasLimit;
Backend.approveUserTask = approveUserTask;
Backend.getContractStandard = getContractStandard;
Backend.verifyOwnership = verifyOwnership;
Backend.getNftGasLimit = getNftGasLimit;
Backend.getNftDropQr = getNftDropQr;
Backend.getNftDropList = getNftDropList;
Backend.verifyApprove = verifyApprove;
export default Backend;
