import request from '@/core/utils/request';
import StorageServices from '../StorageServices';
/**
 * @description: 获取gaslimit
 * @param {string} chainType
 * @param {string} toAddress
 * @param {string} tokenHash
 * @param {string} value
 * @param {boolean} flag
 * @return {*}
 */
const getGasLimit = async (chainType, toAddress, tokenHash, value, flag = true) => {
    const userAddress = StorageServices.getUserAdderss();
    const { data } = await request({
        url: '/airdrop/batch-airdrop-gas',
        method: 'post',
        data: {
            chainType,
            flag,
            toAddress,
            tokenHash,
            value,
            address: userAddress,
        },
    });
    return data;
};
export default getGasLimit;
