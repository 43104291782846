import axios from 'axios';
import i18n from '@/i18n';
import Toast from '@/components/Toast';
import StorageServices from '../Services/StorageServices';
const request = axios.create({
    timeout: 60 * 1000,
    headers: {
        'Content-Type': 'application/json',
    },
});
// request设置认证头
request.interceptors.request.use((config) => {
    // eslint-disable-next-line
    config.baseURL = process.env.VUE_APP_BASE_URL;
    const auth = StorageServices.getLoginAuth();
    if (auth) {
        // eslint-disable-next-line
        config.headers.Authorization = auth;
    }
    return config;
}, (error) => Promise.reject(error));
// response更新认证头，有error按照自动toast，返回异常error_code
request.interceptors.response.use((response) => {
    if (response.headers.authorization) {
        StorageServices.setLoginAuth(response.headers.authorization);
    }
    const errorCode = response.data.error;
    if (errorCode) {
        const errMessage = i18n.global.t(`error_${errorCode}`) || response.data.desc;
        Toast.error(errMessage);
        return Promise.reject(errorCode);
    }
    return response;
}, (error) => {
    if (!axios.isCancel(error)) {
        Toast.error(error.message);
    }
    return Promise.reject(error);
});
export default request;
