import request from '@/core/utils/request';
/**
 * 获取授权二维码
 * @param amount
 * @param chainType
 * @param tokenHash
 */
export const getAuthQr = async (amount, chainType, tokenHash, isNft, gasPrice, gasLimit) => {
    const { data } = await request({
        url: '/airdrop/approve',
        method: 'post',
        data: {
            amount,
            chainType,
            tokenHash,
            isNft,
            gasPrice,
            gasLimit,
        },
    });
    const { result } = data;
    return {
        id: result.id,
        qr: JSON.stringify(result.qrCode),
    };
};
