class StorageServices {
    static storeEditingTask(task) {
        sessionStorage.setItem('editing-task', JSON.stringify(task));
    }
    static getEditingTask() {
        const jsonStr = sessionStorage.getItem('editing-task');
        return jsonStr ? JSON.parse(jsonStr) : null;
    }
    static getLoginAuth() {
        return sessionStorage.getItem('login-auth') || '';
    }
    static setLoginAuth(auth) {
        sessionStorage.setItem('login-auth', auth);
    }
    static getLoginOntId() {
        return sessionStorage.getItem('login-ontid') || '';
    }
    static setLoginOntId(ontId) {
        sessionStorage.setItem('login-ontid', ontId);
    }
    static setUserAdderss(address) {
        sessionStorage.setItem('user-address', address);
    }
    static getUserAdderss() {
        return sessionStorage.getItem('user-address') || '';
    }
    static setUserChains(chains) {
        sessionStorage.setItem('user-chains', JSON.stringify(chains));
    }
    static getUserChains() {
        return JSON.parse(sessionStorage.getItem('user-chains') || '[]');
    }
}
export default StorageServices;
