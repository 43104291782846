import Backend from '@/core/Services/Backend';
import { onBeforeUnmount, ref } from 'vue';
import axios from 'axios';
/**
 * 轮询扫码登录结果
 * @param onSuccess 登录成功回调
 * @returns
 */
const useFetchResult = (onSuccess) => {
    const timer = ref(0);
    const cancelSource = ref(axios.CancelToken.source());
    const startFetchResult = async (id) => {
        cancelSource.value = axios.CancelToken.source();
        Backend.getLoginResult(id, cancelSource.value.token).then(({ state, ontId, auth, address, chains, }) => {
            // 1成功 null未扫码 0失败（会返回异常）
            if (state === '1') {
                onSuccess(ontId, auth, address, chains);
            }
            else {
                timer.value = window.setTimeout(() => {
                    startFetchResult(id);
                }, 1000);
            }
        });
    };
    onBeforeUnmount(() => {
        if (timer.value) {
            window.clearTimeout(timer.value);
        }
        cancelSource.value.cancel();
    });
    return {
        startFetchResult,
    };
};
export default useFetchResult;
