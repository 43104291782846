import { defineComponent, reactive, toRefs, ref, onBeforeUnmount, } from 'vue';
import { throttle } from 'lodash-es';
export default defineComponent({
    name: 'Dropdown',
    props: {
        custom: {
            // 自定义位置,不监听滚动
            type: Boolean,
            required: false,
            default: true,
        },
        right: {
            // 右边贴右侧,左侧不限
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const state = reactive({
            isUnfold: false,
            position: 'bottom',
        });
        const el = ref(null);
        const drop = ref(null);
        const getPosition = () => {
            const containerDom = el.value;
            const dropDom = drop.value;
            if (!containerDom || !dropDom) {
                return 'bottom';
            }
            const containerRect = containerDom.getBoundingClientRect();
            const containerHeight = containerRect.height; // 元素的高度
            const screenHeight = document.documentElement.clientHeight; // 可视区总高度
            const topSpace = containerRect.top; // 元素上空间（相对可视区）
            const bottomSpace = screenHeight - topSpace - containerHeight; // 元素下空间
            const dropHeight = dropDom.clientHeight;
            if (bottomSpace > dropHeight) {
                // 底部空间足够则显示在底部
                return 'bottom';
            }
            if (topSpace > bottomSpace) {
                // 底部不够且上空间大于底部
                return 'top';
            }
            return 'bottom'; // 上空间更小
        };
        const updatePosition = () => {
            state.position = getPosition();
        };
        const updateHandler = throttle(updatePosition, 200);
        const hide = (e) => {
            if (e && drop.value && drop.value.contains(e.target)) {
                // 来自下拉组件的点击不隐藏
                return;
            }
            state.isUnfold = false;
            document.removeEventListener('click', hide);
            if (!props.custom) {
                document.removeEventListener('scroll', updateHandler);
            }
        };
        const show = () => {
            state.isUnfold = true;
            window.setTimeout(() => {
                document.addEventListener('click', hide);
                if (!props.custom) {
                    updatePosition();
                    document.addEventListener('scroll', updateHandler);
                }
            }, 0);
        };
        onBeforeUnmount(() => {
            document.removeEventListener('click', hide);
            document.removeEventListener('scroll', updateHandler);
        });
        const switchFold = () => {
            if (state.isUnfold) {
                hide();
            }
            else {
                show();
            }
        };
        return {
            ...toRefs(state),
            el,
            drop,
            switchFold,
            hide,
            show,
        };
    },
});
