{
  "error_61001": "Incorrect parameter",
  "error_61002": "Data not found",
  "error_61003": "Unable to invoke the contract",
  "error_61004": "Unable to use the QR Code",
  "error_61005": "Expired QR code",
  "error_61006": "The QR code has been scanned",
  "error_62001": "Airdrop status error",
  "error_62002": "Not within the scheduled time period",
  "error_62003": "Excel export error",
  "error_62004": "File format error",
  "error_62005": "Unable to parse the file",
  "error_62006": "The limit of 400 lines has been exceeded",
  "error_62007": "File operation error",
  "error_63001": "Asset contract address not found",
  "error_63002": "Login expired",
  "error_63003": "Asset type error",
  "error_64001": "Third party API error",
  "sun": "Su",
  "mon": "Mo",
  "tue": "Tu",
  "wed": "We",
  "thu": "Th",
  "fri": "Fr",
  "sat": "Sa",
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
  "cancel": "Cancel",
  "set_time": "Save",
  "login": "Log in",
  "scan_login": "QR Code Login",
  "open_onto_scan": "Log in with ONTO App",
  "open_onto_scan_tip": "Open the latest version of ONTO app and press the Scan button in the top right of the “Assets” page.",
  "download_onto_app": "Download ONTO App",
  "scan_download_onto": "Scan to Download ONTO",
  "close_qr": "Close",
  "supported_chains": "Supported Chains",
  "eth_mainnet": "Ethereum ",
  "binance_smart_chain": "BNB Chain",
  "Huobi_ECO_chain": "Huobi ECO Chain",
  "okex_chain": "OKC",
  "welcome_to_use": "Welcome to",
  "assistant_to_drop": "Airdrop Assistant",
  "inc_introduce": "Conduct batch transfers and customizable airdrops to qualified participants",
  "batch_airdrops_of_tokens": "Batch Transfer",
  "batch_airdrops_sub": "Distribute assets to a list of addresses",
  "release_the_airdrop_mission": "Launch Airdrop Programs",
  "launch_small_airdrop_missions": "Roll out small scale airdrop marketing campaigns",
  "to_publish_task": "Launch Airdrop",
  "data_board": "Participant Data Dashboard",
  "view_published_airdrop_data": "View details and results of your airdrop programs",
  "view_data_board": "View Dashboard",
  "pager_left": "Page of",
  "pager_right": "",
  "click_upload_pic": "Click to upload images",
  "drag_here": "or drag files here",
  "re-upload": "Change the image",
  "onto_anydrop": "ONTO Anydrop",
  "airdrop_action": "Airdrops",
  "my_joined": "My Claims",
  "airdrop_before_start": "Upcoming",
  "airdrop_active": "Active",
  "airdrop_end": "Past",
  "check_content": "View Details",
  "remain_time": "Time remaining: {days} days {hours} hours {minutes} minutes",
  "task_name": "Airdrop Title",
  "task_token": "Airdrop Asset",
  "task_token_count": "Airdrop Amount",
  "status": "Status",
  "check": "Details",
  "acton_time": "Schedule",
  "acton_rule": "Guidelines",
  "requirement": "Requirements",
  "go_do": "Fulfill",
  "apply_airdrop": "Claim Airdrop Rewards",
  "go_link": "Open the link",
  "confirm": "Confirm",
  "index": "No.",
  "address": "Address",
  "count": "Amount",
  "add_line": "Add a new line",
  "problem": "Error",
  "invalid_address": "Invalid address",
  "invalid_count": "Invalid amount",
  "invalid_address_count": "Invalid address and amount",
  "invalid_precision": "Invalid amount and decimals",
  "operate_step": "Step-by-Step Guide",
  "task_step1": "1. Set the schedule",
  "task_step1_content": "Choose the starting time and ending time for your airdrop. Participants can only join for the duration.",
  "task_step2": "2. Choose the address network",
  "task_step2_content": "Participants can only submit wallet addresses based on the network you choose.",
  "task_step3": "3. Fill in the description",
  "task_step3_content": "Please fill in a description including the airdrop title, details, etc.; You can provide the description in different languages, and we will display them to respective participants.",
  "task_step4": "4. Specify requirements for participants",
  "task_step4_content": "\"Requirement Description\" is to tell participants what requirement they need to fulfill to claim assets;\n\"Link\" (optional) is to direct participants to the web page to complete the relevant task; \n\"Requirement Type\" is where you allow participants to provide relevant texts and images. Click the buttons below to view a sample or preview the content you have filled.",
  "airdrop_address_type": "Address Network",
  "task_inc_name": "Airdrop Organizer",
  "enter_task_inc_name": "Please enter the name of your organization",
  "task_title": "Airdrop Title",
  "enter_task_title": "Please enter the title of your airdrop program",
  "task_detail": "Details",
  "enter_task_detail": "Please enter details such as guidelines and the timeline",
  "click_add_requirement": "Click to add requirements",
  "des_and_picture": "Descriptions and Pictures",
  "fit_lang": "(Supporting different languages)",
  "enter_start_time": "Choose starting time",
  "enter_end_time": "Choose ending time",
  "next_step": "Next",
  "optional": "(Optional)",
  "avatar": "Profile Photo",
  "avatar_tip": "Supported images: PNG and JPG formats; less than 1MB; an aspect ratio of 1:1",
  "top_banner": "Banner",
  "top_banner_tip": "Supported images: PNG and JPG formats; less than 3MB; an aspect ratio of 2:1",
  "add_requirement": "Add a Requirement",
  "requirement_des": "Requirement Description",
  "for_user": "(Participants will see)",
  "enter_requirement_des": "E.g., wallets should have a BNB balance that's more than 0.5",
  "jump_link": "Link",
  "jump_link_tip": "(Optional. A shortcut for participants to fulfill the requirement)",
  "enter_jump_link": "Please enter a link",
  "requirement_type": "Requirement Type",
  "edit_text": "Text",
  "upload_picture": "Image",
  "text_title": "Title",
  "hour": "H",
  "minute": "M",
  "task_picture_tip": "Supported images: PNG and JPG formats; less than 0.5MB; an aspect ratio of 1:1. 3 images maximum.",
  "slogan": "Welcome to\nONTO Anydrop Airdrop Assistant",
  "user": "Participant",
  "user_intro": "Discover and join airdrops",
  "learn_more": "Learn more",
  "inc": "Organizer",
  "inc_intro": "Conduct batch transfers and customizable airdrops to qualified participants",
  "task_preview": "Program Page Preview",
  "web_end": "Web",
  "mobile_end": "Mobile",
  "submit_time": "Submission Time",
  "airdrop_address": "Wallet Address",
  "upload_three": "Supported images: PNG and JPG formats; less than 3 MB. 3 images maximum.",
  "please_enter_address": "Please enter an address to receive airdrop rewards",
  "ensure_correct_address": "Please ensure the address is valid",
  "submit_success": "Submitted",
  "no_task_tip": "Nothing here yet",
  "no_join_tip": "You haven't joined any airdrops",
  "apply_has_submitted": "Claimed",
  "done": "Done",
  "language": "Language",
  "sign_out": "Log out",
  "switch_to_user": "Switch to participant",
  "switch_to_inc": "Switch to organizer",
  "click_upload_file": "Click to upload",
  "file_example": "File Sample",
  "select_or_input_hash": "Choose an asset or enter a contract address",
  "scan_contract": "Confirm Transaction",
  "over_size_limit": "File size exceeds the limit",
  "over_rate_limit": "Unsupported image aspect ratio",
  "over_type_limit": "Unsupported file format",
  "airdrop_address_count": "Addresses",
  "no_drop_tip": "You haven't done any batch transfer yet. Please click on the button below to start",
  "apply_count": "Claims",
  "edit_content": "Edit",
  "check_data": "Participant Data",
  "publish_task": "Launch",
  "cancel_task": "Cancelled",
  "no_publish_task_tip": "You haven't launched any airdrop yet. Please click on the button below to start",
  "already_auth_token": "Authorized Assets",
  "cancel_auth": "Revoke",
  "back": "Back",
  "multi_drop_detail": "Batch Transfer Details",
  "transfer_count": "Transfers",
  "airdrop_token_count": "Asset Amount",
  "fee": "Transaction Fee",
  "estimate_fee": "Estimated gas",
  "airdrop_record": "Airdrop History",
  "enter_airdrop_address": "Please enter an address",
  "select_token": "Please select an asset",
  "time": "Transfer time",
  "show_all": "All",
  "failed": "Failed",
  "apply_list": "Claims",
  "airdrop_amount": "Amount",
  "save": "Save",
  "all": "All",
  "review_pending": "Pending",
  "passed": "Passed",
  "refused": "Rejected",
  "not_drop": "Untransferred",
  "already_drop": "Transferred",
  "chain_type": "Network",
  "decimal": "Decimal",
  "receive_address_list": "Recipient Wallets",
  "support_file_types": "Supported file formats: Excel, CSV and TXT",
  "drop_step1": "1. Select the asset network",
  "drop_step1_content": "Ethereum, BNB Chain, Polygon and OKC are supported.",
  "drop_step2": "2. Select an asset of enter a contract address",
  "drop_step2_content": "Assets using ERC-20, BEP-20, ERC-20 (Polygon) and KIP-20 protocols are supported. The decimals field is auto-filled when you enter the asset address.",
  "drop_step3": "3. Upload recipient wallet addresses",
  "drop_step3_content": "Once uploaded, the system will automatically detect duplicated addresses and error data. You could edit the information and fix errors directly on this page. Click \"Next\" when all is sorted.",
  "confirm_balance_tip": "Note: Please make sure the balance in your wallet is sufficient for the airdrop transfer and transaction fee",
  "transfer_fee": "Transaction Fee",
  "please_enter": "Please enter",
  "custom": "Custom",
  "auth_title": "Authorization",
  "check_demo": "Sample",
  "preview_form": "Preview",
  "unfilled": "Required",
  "inc_name_unfilled": "Organizer name is required",
  "time_unfilled": "Schedule is required",
  "rule_unfilled": "Guidelines are required",
  "requirement_unfilled": "Requirements are required",
  "authorization_limit": "Asset Authorization",
  "send": "Send",
  "please_upload_excel": "Unsupported file format. Please upload a Excel, CSV or TXT file.",
  "please_upload_image": "Unsupported file format. Please upload a PNG or JPG file.",
  "copy_success": "Copied",
  "contract_not_found": "Contract not found",
  "requirement_not_filled": "Requirements not met",
  "submit_task_success": "Airdrop Saved",
  "enter_valid_address": "Please enter a valid address",
  "no_time": "Choose time period",
  "invalid_time": "The start time cannot be later than the end time",
  "no_lang_form": "Please provide a description and images",
  "not_filled_lang_form": "Description and images are required",
  "not_matched_requirement": "Language does not match",
  "NotRelease": "Unpublished",
  "BeforeStart": "Upcoming",
  "Active": "Active",
  "End": "Past",
  "no_authorization": "No authorization yet",
  "login_success": "Login confirmed",
  "drop_form_invalid": "Information required for the batch transfer is incomplete",
  "delete_invalid_address": "Delete Invalid Addresses",
  "keep_duplicate_address": "Keep Duplicates",
  "keep_first_one": "Remove Duplicates",
  "address_duplicate": "Hmm...These addresses seem to be repeated",
  "address_or_amount_invalid": "Oops. Something's wrong with the below addresses",
  "start_time_before_now": "The start time cannot be later than the end time",
  "pass": "Approve",
  "not_pass": "Reject",
  "review_success": "Approved",
  "batch_drop": "Batch Transfer",
  "aerial_mission": "Airdrop",
  "authorization_management": "Authorization",
  "send_successfully": "Sent",
  "low": "Low",
  "average": "Average",
  "high": "High",
  "number_of_tokens_sent": "Number of tokens sent",
  "transaction_closed": "Transaction finished ",
  "trading_hash": "Transaction Hash",
  "polygon_chain": "Polygon",
  "total_size": "{total} items，{size} per page",
  "the_authorization": "Authorization in Progress",
  "please_wait": "Transaction in progress, please stay on this page.",
  "authorization_success": "Authorization Complete  ",
  "authorization_fails": "Authorization failed. Please try again.",
  "transform": "Switch",
  "transform_hint": "Please switch ex- address to 0x- address",
  "drop_nft": "NFT batch airdrop",
  "drop_nft_sub":"NFT batch airdrop to addresses in existing list ",
  "nft_contract_address": "NFT contract address",
  "enter_nft_contract_address": "Enter NFT contract address",
  "contract_standard": "Standard",
  "upload_form": "Upload form",
  "manual_input": "Enter manually",
  "nft_step1": "1. Select asset network",
  "nft_step1_content": "NFT airdrop on Ethereum, BNB Chain, Polygon and OKC is supported. You need to create or import wallet from the corresponding network, and make sure your wallet balance is sufficient for the NFT airdrop and transaction fee.   ",
  "nft_step2": "2. Enter NFT contract address",
  "nft_step2_content": "Support NFT airdrop for all ERC-721/1155, BEP-721/1155, ERC-721/1155 (Polygon) and KIP-721/1155 standards",
  "nft_step3": "3. Upload file with airdrop list",
  "nft_step3_content": "Once uploaded, the system will automatically detect duplicated addresses and error data. You can edit the information and fix errors directly on this page. Click \"Next\" to authorize.",
  "invalid_address_or_tokenid_or_quantity":"Invalid wallet address/invalid Token ID/invalid airdrop amount",
  "address_not_in_wallet":"The token below is not in the current wallet address",
  "no_ownership":"Token doesn't belong to the current wallet ",
  "nft_contract":"NFT contract",
  "number_of_nft_airdropped":"Number of NFT airdropped",
  "tokenid_repetition":"Repeated Token ID",
  "nft_excess":"Insufficient NFT balance"
}