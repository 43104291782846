import request from '@/core/utils/request';
/**
 * @description: 获取单条批量空投中的详细信息列表
 * @param {number} page
 * @param {number} size
 * @param {string} id
 * @param {string} status
 * @param {string} address
 * @return {*}
 */
const getDropDetailList = async (page, size, cancelToken, id, status, address) => {
    const { data } = await request({
        url: `/airdrop/batch-airdrop/detail/${id}`,
        method: 'get',
        params: {
            pageNumber: page,
            pageSize: size,
            status,
            address,
        },
        cancelToken,
    });
    if (data.error) {
        throw new Error(data.desc);
    }
    return {
        record: data.result.record,
        count: data.result.count,
    };
};
export default getDropDetailList;
