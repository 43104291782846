import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { onBeforeUnmount, watch, nextTick, } from 'vue';
const useLock = (domRef, isVisibleRef) => {
    watch(isVisibleRef, (val) => {
        if (val) {
            nextTick(() => {
                if (!domRef.value) {
                    return;
                }
                disableBodyScroll(domRef.value);
            });
        }
        else {
            if (!domRef.value) {
                return;
            }
            enableBodyScroll(domRef.value);
        }
    });
    onBeforeUnmount(() => {
        clearAllBodyScrollLocks();
    });
};
export default useLock;
