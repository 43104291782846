import request from '@/core/utils/request';
/**
 * 查询单条空投任务的申请列表
 * @param id 空投任务id
 * @param status 空头任务状态
 * @param cancelToken
 * @param page 页码
 * @param size 每页条数
 */
const getTaskDetailList = async (page, size, cancelToken, id, status) => {
    const { data } = await request({
        url: '/airdrop/user-info',
        method: 'get',
        params: {
            id,
            status,
            pageNumber: page,
            pageSize: size,
        },
        cancelToken,
    });
    if (data.error) {
        throw new Error(data.desc);
    }
    return {
        record: data.result.record,
        count: data.result.count,
    };
};
export default getTaskDetailList;
