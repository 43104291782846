import request from '@/core/utils/request';
import StorageServices from '../StorageServices';
/**
 * @description: 获取nft合约标准
 * @param {string} chainType
 * @param {string} contract
 * @param {string} userAddress
 * @return {*}
 */
const getContractStandard = async (chainType, contract) => {
    const userAddress = StorageServices.getUserAdderss();
    const { data } = await request({
        url: '/airdrop/get-nft-contract-type',
        method: 'get',
        params: {
            chainType,
            contract,
            address: userAddress,
        },
    });
    return data;
};
export default getContractStandard;
