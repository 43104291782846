export var Lang;
(function (Lang) {
    Lang["en"] = "en";
    Lang["zh"] = "cn";
})(Lang || (Lang = {}));
// TODO 与i18n文案key解耦
export var TaskFormError;
(function (TaskFormError) {
    TaskFormError["NoError"] = "";
    TaskFormError["NoTime"] = "no_time";
    TaskFormError["InvalidTime"] = "invalid_time";
    TaskFormError["StartBeforeNow"] = "start_time_before_now";
    TaskFormError["NoLangForm"] = "no_lang_form";
    TaskFormError["NotFilledLangForm"] = "not_filled_lang_form";
    TaskFormError["NotMatchedRequirement"] = "not_matched_requirement"; // 多语言申领条件不匹配
})(TaskFormError || (TaskFormError = {}));
export var ChainAndCoin;
(function (ChainAndCoin) {
    ChainAndCoin["eth"] = "ETH";
    ChainAndCoin["polygon"] = "MATIC";
    ChainAndCoin["bsc"] = "BNB";
    ChainAndCoin["okex"] = "OKT";
})(ChainAndCoin || (ChainAndCoin = {}));
export var ChaintoFullname;
(function (ChaintoFullname) {
    ChaintoFullname["eth"] = "Ethereum";
    ChaintoFullname["bsc"] = "BNB Chain";
    ChaintoFullname["polygon"] = "Polygon Chain";
    ChaintoFullname["okex"] = "OKC";
})(ChaintoFullname || (ChaintoFullname = {}));
