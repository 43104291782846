import { defineComponent, reactive, toRefs } from 'vue';
import QrBox from './QrBox.vue';
export default defineComponent({
    name: 'QrView',
    props: {
        qr: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },
    emits: ['close'],
    components: {
        QrBox,
    },
    setup(props, { emit }) {
        const state = reactive({
            isDownload: false,
        });
        const closeHandler = () => {
            emit('close');
        };
        return {
            ...toRefs(state),
            closeHandler,
        };
    },
});
